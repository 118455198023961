<template>
  <validation-provider ref="provider"
                       :rules="rules"
                       :vid="vid"
                       :name="name"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-text-box phone-mode" :class="classes">
      <label class="label base-field-label"
             v-if="label"
             @click="focus">{{ label }}</label>

      <input :id="id"
             ref="input"
             class="input"
             :name="name"
             :type="type"
             :value="value"
             :disabled="disabled"
             :autocomplete="autocomplete"
             v-autofocus="autofocus"
             :placeholder="placeholder"
             @input="onInput"
             @change="onChange"
             :inputmode="inputmode"
             :pattern="pattern">

      <div class="error"
           v-show="showErrors && errors.length > 0">
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
  import validatable from '@/services/mixins/validatable.js'
  import $ from 'jquery'
  import 'intl-tel-input'
  import 'intl-tel-input/build/js/utils'

  export default {
    mixins: [
      validatable
    ],

    props: {
      id: {
        required: false,
        type: String
      },

      value: {
        required: true
      },

      type: {
        required: false,
        type: String,
        default: 'text'
      },

      inputmode: {
        required: false,
        type: String,
        default: 'text'
      },

      pattern: {
        required: false,
        type: String,
        default: null
      },

      name: {
        required: false,
        type: String,
        default: 'name'
      },

      label: {
        required: false,
        type: String
      },

      placeholder: {
        required: false,
        type: String,
        default: ''
      },

      showValidationError: {
        required: false,
        type: Boolean,
        default: false
      },

      errorText: {
        required: false,
        type: String
      },

      validationRules: {
        required: false
      },

      phoneOptions: {
        required: false,
        type: Object,
        default: () => {
          return {}
        }
      },

      autofocus: {
        required: false,
        type: Boolean
      },

      autocomplete: {
        required: false,
        type: String
      },

      rounded: {
        required: false,
        type: Boolean,
        default: false
      },

      multiLine: {
        required: false,
        type: Boolean,
        default: false
      },

      rows: {
        required: false,
        type: Number
      },

      disabled: {
        required: false,
        type: Boolean,
        default: false
      },

      showPasswordRecoveryLink: {
        required: false,
        type: Boolean,
        default: false
      },

      showCharsCounter: {
        required: false,
        type: Boolean,
        default: false
      },

      maxChars: {
        required: false,
        type: Number
      }
    },

    mounted() {
      this.initPhoneNumber()
    },

    methods: {
      onInput(event) {
        this.$emit('input', event.target.value)
      },

      onChange(event) {
        this.$emit('change', event.target.value)
      },

      validate() {
        return this.$validator.validateAll()
      },

      focus() {
        this.$refs.input.focus()
      },

      initPhoneNumber() {
        let $selector = $(this.$refs.input)

        $selector.intlTelInput({
          initialCountry: 'auto',

          geoIpLookup: function (callback) {
            // TODO: Temporary disable cache
            // Best approach is to change cache when country is selected manually
            // if (localStorage.getItem('country_code')) {
            //   callback(localStorage.getItem('country_code'))
            //   return
            // }

            $.get('https://ipinfo.io', function () {
            }, 'jsonp').always((response) => {
              let countryCode = (response && response.country) ? response.country : ''
              // localStorage.setItem('country_code', countryCode)
              callback(countryCode)
            })
          },

          ...this.phoneOptions
        })

        $selector.on('countrychange', (event, countryData) => {
          this.$emit('countryChange', countryData)
        })
      },

      getCountryCode() {
        let countryData = $(this.$refs.input).intlTelInput('getSelectedCountryData')

        return countryData.iso2
      }
    },

    computed: {
      classes() {
        return [
          { 'rounded':    this.rounded },
          { 'disabled':   this.disabled }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped="">


  .base-text-box {
    position: relative;
    margin-bottom: 20px;

    .input {
      width: 100%;
      height: 46px;

      padding: 0;

      background: #fff;
      border: 0;
      border-bottom: 1px solid #E6E1E1;
      outline: none;

      font-size: 16px;
      color: #1C1C1C;
    }

    &.rounded {
      .input, .textarea {
        border: 1px solid #E6E1E1;
        border-radius: 100px;
        padding: 20px;
      }
    }

    &.phone-mode, &.rounded.phone-mode {
      .input {
        padding-left: 60px !important;
      }
    }

    &.not-valid {
      border-color: #FF8686;
    }

    &.disabled {
      input, textarea {
        border-bottom: 2px dotted;
      }
    }

    .error {
      color: #FF7474;
    }
  }

  .intl-tel-input {
    display: block;

    .selected-flag {
      outline: none !important;
      padding: 0 0 0 16px;

      .iti-arrow {
        right: -6px;
      }
    }
  }

  .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: transparent;
  }
</style>
